<template>
  <div class="template-content">
    <div class="governance-box-content">
      <div class="governance-box-right">
        <div class="governance-box-info governance-box-wallet">
          <h2 class="governance-mod-title">
            全部提案
            <i
              class="el-tooltip el-icon-question"
              aria-describedby="el-tooltip-7296"
              tabindex="0"
            ></i>
          </h2>
          <div class="governance-mod-desc">
            <ul>
              <li>钱包余额： 0.0000 ROO</li>
              <li>我的选票： 0 票</li>
            </ul>
            <div class="governance-mod-opera">
              <button
                class="governance-opear-btn governance-opear-btn-blue"
                style="margin-top: 10px"
              >
                去锁仓质押获取更多选票
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="governance-box-list">
        <h2 class="governance-mod-title">全部提案</h2>
        <ul class="governance-mod-list"></ul>
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="授权启用投票"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 590px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">授权启用投票</span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="授权中"
        class="el-dialog DAWPop ant-modal"
        style="margin-top: 0px; width: 590px"
      >
        <div class="el-dialog__header">
          <span class="el-dialog__title">授权中</span
          ><button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
          >
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="兑换选票"
        class="el-dialog ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <div class="el-dialog__header-slot">兑换选票</div>
          <button type="button" aria-label="Close" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
    <div class="el-dialog__wrapper" style="display: none">
      <div
        role="dialog"
        aria-modal="true"
        aria-label="选票兑现"
        class="el-dialog ant-modal"
        style="margin-top: 0px; width: 600px"
      >
        <div class="el-dialog__header">
          <div class="el-dialog__header-slot">选票兑现</div>
          <button type="button" aria-label="Close" class="el-dialog__headerbtn">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <!----><!---->
      </div>
    </div>
  </div>
</template>

<script>
export default {
 
  data() {
    return {
      
    };
  },
};
</script>

<style scoped>

</style>